import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import HeaderImage from 'components/header/header-image/headerImage.js';
import ContactForm from 'components/contact-form/contactForm.js'

const ContactPage = ({ data }) => (
  <Layout buttonClass="white">
    <HeaderImage title="KONTAKT" />
    <div className="contactPage">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pl-0">
            <div className="contactPage__icons mt-9">
              <div className="contactPage__box contactPage__address">
              <img src="pictures/place.svg" alt="ikona lokalizacji" />
              <span className="mt-6">
                  ul. Widok 8 lok. 5 <br />
                  00-023 Warszawa
                </span>
              </div>
              <div className="contactPage__box contacPage__email ">
              <img src="pictures/message.svg" alt="ikona email" />
                <span className="mt-6">biuro@bartoszbator.pl</span>
              </div>
              <div className="contactPage__box contactPage__telephone">
              <img src="pictures/phone-call.svg" alt="ikona telefonu" />
                <a className="mt-6" href="tel:+48 603 082 948">+ 48 603 082 948</a>
              </div>
            </div>
            <div className="contactPage__headline mt-9 pl-0">
              <h3>
                JEŻELI MASZ JAKIEKOLWIEK PYTANIA <br /> WYŚLIJ DO NAS WIADOMOŚĆ
              </h3>
            </div>
          </div>
        </div>
        <div className="row mt-7">
          <ContactForm/>
        </div>
      </div>
    </div>
  </Layout>
);

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;
