import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("xknogddv"); // Twój identyfikator formularza Formspree
  const [email, setEmail] = useState(''); // Stan lokalny dla przechowywania adresu e-mail

  // Funkcja do aktualizacji stanu e-maila przy każdej zmianie wartości inputa
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Jeśli formularz został wysłany pomyślnie, wyświetl podziękowanie z adresem e-mail
  if (state.succeeded) {
    return <p className="thanks-message">Dziękujemy za wysłanie wiadomości, {email}.</p>;
  }

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <input
        id="name"
        type="text" // Ustaw typ jako text zamiast name dla spójności
        name="name"
        required
        placeholder="IMIĘ I NAZWISKO"
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <input
        id="email"
        type="email"
        name="email"
        required
        placeholder="ADRES EMAIL"
        value={email}
        onChange={handleEmailChange} // Przechwytuj każdą zmianę w polu e-mail
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <input
        id="title"
        type="text" // Typ powinien być text, ponieważ HTML nie ma typu 'title'
        name="title"
        required
        placeholder="TEMAT"
      />
      <ValidationError 
        prefix="Title" 
        field="title"
        errors={state.errors}
      />
      <textarea
        id="message"
        name="message"
        placeholder="TEKST WIADOMOŚCI"
        rows="20"
        required
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        Wyślij
      </button>
    </form>
  );
}

export default ContactForm;
